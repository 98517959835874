import Vue from "vue";
import router from "./index";
import store from "../store/index";
import { includes } from "lodash";
import { isSuperAdmin, isAdmin, enablePoint } from "@/utils/index.js";

let sysModulePermisson, permissionList;

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = "StarPay-Works " + to.meta.title;
  } else {
    document.title = "StarPay-Works";
  }
  store.commit("app/clearCancelToken");
  console.log("[ in permission ]", { a: Vue.prototype.$settings, b: store.state.app.userInfo });
  //增加判断逻辑，如果系统维护，就跳转到维护界面
  if (Vue.prototype.$settings.showServiceUnavailable && store.state.app.userInfo.roleName !== "SystemConfig") {
    window.location.replace("/m.html");
  }
  if (to.meta.permission) {
    sysModulePermisson = store.state.app.userInfo.sysModulePermisson;
    console.log("sysModulePermisson");
    console.log(sysModulePermisson);
    permissionList = sysModulePermisson.map(item => item.modelName);
    console.log(permissionList);
    if (!isAdmin()) {
      let paths = ["systrade", "adminUser", "sysMessageManagement"];
      if (!enablePoint()) {
        paths = paths.concat(["Order", "TradePoint"]);
      }
      if (includes(paths, to.name)) {
        next("/notFound");
      } else {
        includes(permissionList, to.meta.permission) ? next() : next("/panel");
      }
    } else {
      includes(permissionList, to.meta.permission) ? next() : next("/notFound");
    }
  } else {
    (isSuperAdmin() || isAdmin()) && to.name === "Panel" ? next("/notFound") : next();
  }
});
