export default {
  operation: "操作",
  dateTime: "日時",
  date: "日付",
  terminal: "端末",
  terminalHint: "0000",
  store: "店舗",
  storeName: "店舗名",
  storeCode: "店舗コード",
  cashCount: "金額",
  printCount: "印刷回数",
  storAbbreviation: "店舗略称",
  enterpriseAbbreviation: "企業略称",
  enterpriseCode: "企業コード",
  enterpriseName: "企業名称",
  enterprise: "企業",
  payDateTime: "支払日時",
  payKind: "決済種別",
  payKindHint: "すべての決済種別",
  paymentNo: "支払番号",
  outTradeNo: "取引番号",
  otherWalletNumber: "イシュア決済番号",
  deviceIdentificationNumber: "端末識別番号",
  deviceProcessingNumber: "端末​処理番号",
  tradeKind: "取引種別",
  tradKindHint: "全部",
  searchReset: "条件をリセット",
  search: "検索する",
  recordDownload: "データをダウンロード",
  passage: "件",
  payment: "支払",
  paymentKind: "決済種別",
  kind: "取引種別",
  all: "全部",
  refund: "返金",
  refundDate: "返金日時",
  refundNo: "返金番号",
  cashAll: "全額",
  licenseNo: "ライセンス番号",
  terminalCount: "端末数",
  terminalNo: "端末番号",
  unknownDeviceModel: "未確定",
  appVer: "アプリバージョン",
  device: "端末種別",
  lastLoginTime: "最終ログイン時間",
  status: "状態",
  login: "ログイン",
  logout: "ログアウト",
  userId: "ユーザーID",
  userName: "ユーザー名",
  userIdInputHint: "ユーザーIDを入力してください",
  password: "パスワード",
  passwordInputHint: "パスワードを入力してください",
  forgotPassword: "パスワードをお忘れの方はこちら",
  name: "名前",
  authority: "ロール",
  userAuthority: "ロール",
  address: "メールアドレス",
  delete: "削除",
  disable: "無効化する",
  trade: "取引管理",
  //dPoint
  orderManage: "注文管理",
  paymentManage: "支払取引管理",
  tradePoint: "ポイント取引管理",
  //dpoint end
  dashboard: "ダッシュボード",
  receipt: "入金検索",
  setting: "各種設定",
  supportCenter: "サポートセンター",
  detail: "詳細",
  bankCode: "金融機関コード",
  bankName: "金融機関名",
  bankBranchName: "支店名",
  bankBranchCode: "支店コード",
  bankNo: "口座番号",
  bankNoType: "口座名義",
  bankKind: "口座種別",
  hankakukana: "半角ｶﾅ",
  paymentDetail: "入金の詳細",
  paymentShopDetail: "店舗別利用額",
  payDate: "支払日",
  paymentDetailMore: "さらに詳しい収納金明細書をエクセル形式でダウンロードすることが出来ます。",
  paymentDetailMoreDownload: "収納金明細書をダウンロードする",
  dataDownload: "データをダウンロード",
  brand: "決済種別",
  notification: "通知",
  noNotification: "お知らせなし",
  importNotification: "重要通知",
  otherNotification: "お知らせ．キャンペーン．その他",
  brandTypeRatio: "決済種別割合",
  ratio: "割合",
  currentDay: "今日",
  currentWeek: "今週",
  currentMonth: "今月",
  today: "今日",
  yestoday: "昨日",
  lastWeek: "先週",
  lastMonth: "先月",
  quarter: "四半期",
  year: "今年",
  compareWithLastMonth: "前月同時期の割合",
  comparedTheSameDayLastMonth: "前月同時期の割合",
  comparedTheSameWeekLastMonth: "前月同時期の割合",
  comparedLastMonth: "前月同時期の割合",
  comparedTheSameMonthLastMonth: "前月同時期の割合",
  comparedTheSameQuarterLastYear: "前年同時期の割合",
  comparedLastYear: "前年同時期の割合",
  comparedAmountToday: "先月金額",
  comparedAmountYestoday: "先月金額",
  comparedAmountCurrentWeek: "先月金額",
  comparedAmountCurrentMonth: "先月金額",
  comparedAmountLastMonth: "先々月金額",
  comparedAmountQuarter: "昨年金額",
  comparedLastYearYear: "前年金額",
  comparedAmountYear: "昨年金額",
  startDate: "開始日付",
  endDate: "終了日付",
  moneyUnit: "円",
  money: "金額",
  count: "件数",
  countUnit: "件",
  editPassword: "パスワードを変更する",
  newPassword: "新しいパスワード",
  oldPassword: "現在のパスワード",
  rePassword: "新しいパスワード（確認用）",
  pwdDiffMsg: "確認パスワードが一致しません。",
  savePassword: "パスワード変更",
  currentPassword: "現在のパスワード",
  save: "保存",
  effective: "有効",
  uneffective: "無効",
  email: "メール",
  phoneNum: "連絡先",
  cancel: "キャンセル",
  submit: "申し込む",
  success: "成功",
  fail: "失敗",
  confirm: "確認",
  on: "オン",
  off: "オフ",
  getVerCode: "認証コードを送信する",
  verCode: "認証コード",
  reSendVerCode: "{times} s後再送",
  more: "もっと見る",
  toggleMenu: "メニューをしまう",
  onlineHelp: "オンラインヘルプ",
  remark: "備考",
  editRemark: "備考編集",
  tips: {
    dataSearchDelay: "*決済してから本画面へ反映されるまで若干のタイムラグがあります。ご了承ください。"
  },
  twoStepAuth: "2段階認証",
  twoStepEmail: "認証用メールアドレス",
  confirmTwoStepEmail: "認証用メールアドレス(確認用)",
  configuredTwoStepEmail: "設定済みのメールアドレス",
  twoStepSetSuccess: "２段階認証有効化は完了しました。",
  resetMFA: "メールアドレス変更",
  rules: {
    requireEmail: "メールアドレスを入力してください",
    errorEmail: "正しいメールアドレスを入力してください",
    errorConfirmEmail: "2つの入力欄に入力したメールアドレスが一致していません"
  },
  //数据罗盘
  dataCompassRouter: "データコンパス",
  dataScreenRouter: "データ分析",
  dataScreen: "取引データ",
  tradeDailyRouter: "日次分析",
  tradeMonthlyRouter: "月次分析",
  download: "ダウンロード",
  fontSize: "文字の大きさ",
  large: "大",
  medium: "中",
  small: "小",
  forceMFA: "2段階認証",
  MFAManagement: "2段階認証の設定",
  submitFeedback: "提出",
  pointChargeType: {
    BONUS: "金額基本ポイント",
    BONUS_BONUS: "金額ボーナスポイント",
    BONUS_COUPON: "金額クーポンポイント",
    QUANTITY: "数量基本ポイント",
    QUANTITY_COUPON: "数量クーポンポイント",
    PROMOTION: "キャンペーンポイント",
    REVISION: "補正ポイント"
  }
};
