import Vue from "vue";
import axios from "axios";
import store from "@/store/index";
import qs from "qs";
import { Message } from "element-ui";
import i18n from "../lang/index";

console.log("vue prototype $settings");
console.log(Vue.prototype.$settings);
const http = axios.create({
  baseURL: "/apiproxy",
  paramsSerializer: params => {
    const requestParam = qs.stringify(params, { indices: false });
    return requestParam;
  }
});

http.defaults.headers.post["Content-Type"] = "application/json";

let systemLanguage = window.navigator.language || window.navigator.userLanguage;

http.interceptors.request.use(
  config => {
    if (store.state.app.token) {
      config.headers["Authorization"] = `Bearer ${store.state.app.token}`;
      config.headers["Accept-Language"] = store.state.app.language;
    }

    if (Vue.prototype.$settings) {
      console.log("in request");
      console.log(Vue.prototype.$settings);
      // config.url = Vue.prototype.$settings.apiUrl + config.url;
      config.headers["tenant"] = Vue.prototype.$settings.oem;

      //API版本，如果是新版店铺，则调用2.0
      if (Vue.prototype.$settings.newStoreVersion === true) {
        config.headers["api-version"] = "2.0";
      } else {
        config.headers["api-version"] = "1.0";
      }
    }

    config.cancelToken = new axios.CancelToken(function(cancel) {
      store.commit("app/pushCancelToken", { cancelToken: cancel });
    });
    //获取浏览器语言，用于下载压缩包文件编码
    config.headers["SystemLanguage"] = systemLanguage.substr(0, 2);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  async response => {
    let msg = "";
    let notificationType = 0; //0 succcess 1 error 2 waring 3 info
    let responseData = response.data;
    if (response.config.responseType == "blob") {
      if (response.headers["content-type"].includes("application/json")) {
        responseData = await new Promise(function(resolve) {
          let reader = new FileReader();
          reader.readAsText(response.data);
          reader.onload = function() {
            resolve(JSON.parse(this.result));
          };
        });
      } else {
        return response;
      }
    }
    switch (responseData.resultType) {
      case 1000: //操作成功
        notificationType = 0;
        break;
      case 1010: //系统错误
        msg = i18n.tc("error");
        notificationType = 1;
        break;
      case 1020: //SSO错误
        msg = i18n.tc("ssoError");
        notificationType = 1;
        break;
      case 1030: //操作失败
        msg = i18n.tc("httpFail");
        notificationType = 1;
        break;
      case 1060: //引用服务不可用
        msg = i18n.tc("referenceServiceUnavailable");
        notificationType = 1;
        break;
      case 401: //SSO内部授权失败
        msg = i18n.tc("httpFail");
        notificationType = 5;
        break;
      case 1200: //非法操作
        msg = i18n.tc("illegalOperation");
        notificationType = 1;
        break;
      case 1600: //取引検索期間の範囲は三ヶ月以内に指定してください
        msg = i18n.tc("tradeSearchPeriodLimit");
        notificationType = 2;
        break;
      case 1700: //集計期間の範囲は一ヶ月以内に指定してください
        msg = i18n.tc("statisticSearchPeriodLimit");
        notificationType = 2;
        break;
      case 1300:
        msg = i18n.tc("queryNull");
        notificationType = 2;
        break;
      case 4001: //指定参数的数据不存在
        msg = i18n.tc("queryNull");
        notificationType = 2;
        break;
      case 2000: //权限不足
        msg = i18n.tc("purviewLack");
        notificationType = 1;
        break;
      case 2001: //角色使用中，禁止删除
        msg = i18n.tc("roleUsed");
        notificationType = 1;
        break;
      case 2002: //角色已存在
        msg = i18n.tc("roleExist");
        notificationType = 1;
        break;
      case 2003: //角色不存在
        msg = i18n.tc("roleNotExist");
        notificationType = 1;
        break;
      case 3000: //用户名/密码不匹配
        msg = i18n.tc("incorrectUserInfo");
        notificationType = 3;
        break;
      case 3100: //用户不存在
        msg = i18n.tc("userNotExist");
        notificationType = 2;
        break;
      case 3200: //新密码不能跟旧密码一样
        msg = i18n.tc("newNotEqualsOld");
        notificationType = 2;
        break;
      case 3300: //无效的密码格式，最少8位，而且要包含：小写，大写，数字，特殊符号
        msg = i18n.tc("invalidPasswordFormat");
        notificationType = 2;
        break;
      case 3310: //不能是历史密码
        msg = i18n.tc("invalidPasswordHistory");
        notificationType = 2;
        break;
      case 3400: //用户已存在
        msg = i18n.tc("userExist");
        notificationType = 2;
        break;
      case 3500: //用户被禁用
        msg = i18n.tc("userDisadbled");
        notificationType = 2;
        break;
      case 3600: //用户名已存在
        msg = i18n.tc("userNameExist");
        notificationType = 2;
        break;
      case 3700: //邮箱已注册
        msg = i18n.tc("userEmailExist");
        notificationType = 2;
        break;
      case 3800: //用户名不能包含在密码中
        msg = i18n.tc("passwordCanNotContainUserName");
        notificationType = 2;
        break;
      case 3900: //企业不存在
        msg = i18n.tc("entNotExist");
        notificationType = 2;
        break;
      case 4000: //返金错误
        msg = i18n.tc("refundError");
        notificationType = 1;
        break;
      case 4002: //订单已返金
        msg = i18n.tc("orderRefunded");
        notificationType = 2;
        break;
      case 4003: //超过可退款金额
        msg = i18n.tc("refundExceed");
        notificationType = 2;
        break;
      case 4004: //无效的返金金额
        msg = i18n.tc("refundFeeError");
        notificationType = 2;
        break;
      case 4005: //返金失败
        msg = i18n.tc("refundFail");
        notificationType = 1;
        break;
      case 4008: //信用卡反金
        // msg = i18n.tc("refundDelay");
        notificationType = 0;
        break;
      default:
        msg = responseData.message;
        notificationType = 2;
        break;
    }
    switch (notificationType) {
      case 0:
        return responseData;
      case 1:
        Message({
          showClose: true,
          message: msg,
          type: "error",
          duration: 5000
        });
        break;
      case 2:
        Message({
          showClose: true,
          message: msg,
          type: "warning",
          duration: 5000
        });
        break;
      case 5:
        store.dispatch("app/logout").then(() => {
          location.reload();
        });
        break;
      case 6:
        Message({
          showClose: true,
          message: msg,
          type: "success",
          duration: 5000
        });
        break;
      default:
        Message({
          showClose: true,
          message: msg,
          duration: 5000
        });
        break;
    }
    return responseData;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          Message({
            showClose: true,
            message: i18n.tc("paramError"),
            type: "error",
            duration: 5000
          });
          break;
        case 401:
          store.dispatch("app/logout").then(() => {
            location.reload();
          });
          break;
        case 403:
          Message({
            showClose: true,
            message: i18n.tc("purviewLack"),
            type: "error",
            duration: 5000
          });
          break;
        case 500:
          Message({
            showClose: true,
            message: i18n.tc("error"),
            type: "error",
            duration: 5000
          });
          break;
        default:
          Message({
            showClose: true,
            message: error.message,
            type: "error",
            duration: 5000
          });
          break;
      }
    } else if (error.request) {
      Message({
        showClose: true,
        message: i18n.tc("networkError"),
        type: "error",
        duration: 5000
      });
    } else {
      if (error.message != "requestcancel") {
        Message({
          showClose: true,
          message: error.message,
          type: "error",
          duration: 5000
        });
      }
    }
    return Promise.resolve(error);
  }
);

export default http;
